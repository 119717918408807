import React, { useState } from 'react';

import { navigate } from 'gatsby';

import {
  Container,
  Box,
  Typography,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  CardActionArea,
  Collapse,
  Button,
  InputAdornment,
  TextField,
  Divider,
  useTheme,
  useMediaQuery,
  Paper,
} from '@mui/material';

import { nanoid } from 'nanoid';

// Ícones
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyIcon from '@mui/icons-material/Key';
import LoginIcon from '@mui/icons-material/Login';
import SearchIcon from '@mui/icons-material/Search';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Logo from '@assets/newlogo.svg';

import { SuporteButton } from '@components/form/SupoteButton';
import { FullContainer } from '@components/layout';
import { useDevice } from '@components/utils';

/**
 * Página Central de Ajuda - Projetada para oferecer suporte ao usuário
 *
 * Design inspirado em práticas de UX premium, com foco na clareza
 * e facilidade de navegação. Elementos visuais cuidadosamente
 * posicionados para criar hierarquia visual e experiência fluida.
 */
const CentralDeAjuda = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isDesktop } = useDevice();
  const iconColor = '#4A5568'; // Cor consistente para ícones

  // Função para alternar a expansão dos cards
  const handleExpandCard = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  // Filtra o conteúdo com base no termo de pesquisa
  const filteredContent = content.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.content.some(
        (text) =>
          typeof text === 'string' &&
          text.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  // URL do WhatsApp para suporte
  const whatsappUrl = `https://${
    isDesktop() ? 'web' : 'api'
  }.whatsapp.com/send?phone=5511988433679`;

  return (
    <FullContainer
      className="flex flex-col min-h-screen"
      sx={{
        background: 'linear-gradient(197.56deg, #D9E1F2 0%, #FFFFFF 100%)',
      }}
    >
      {/* Barra do logo com a cor dos ícones */}
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: iconColor,
        }}
      >
        <Toolbar
          className={`
            py-4 
            justify-center
          `}
        >
          <Box
            component={Logo}
            className={`
              h-16
              sm:h-20
              [&_svg]:h-full
            `}
            sx={{ filter: 'brightness(0) invert(1)' }}
            aria-label="Logo da aplicação"
          />
        </Toolbar>
      </AppBar>

      {/* Cabeçalho da página - fora da barra do logo */}
      <Container maxWidth="md">
        <Box
          className={`
            flex 
            flex-col 
            sm:flex-row 
            justify-between 
            items-center
            py-6
            sm:py-8
            mb-2
          `}
        >
          <Typography
            variant="h4"
            className={`
              font-bold 
              text-gray-800
              text-center
              sm:text-left
              mb-4
              sm:mb-0
            `}
            sx={{
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
              letterSpacing: '0.25px',
            }}
          >
            Central de Ajuda
          </Typography>

          <Button
            variant="outlined"
            color="inherit"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
            className={`
              hover:bg-gray-100/50
              border-gray-300
              text-gray-700
            `}
            sx={{
              borderRadius: '8px',
              px: 3,
              py: 1,
              textTransform: 'none',
              fontWeight: 500,
              transition: 'all 0.2s',
              '&:hover': {
                transform: 'translateX(-2px)',
              },
            }}
          >
            Voltar
          </Button>
        </Box>
      </Container>

      {/* Conteúdo principal */}
      <Container maxWidth="md" className="flex-1 pb-12">
        {/* Campo de pesquisa elegante */}
        <Paper
          elevation={0}
          className={`
            mb-10
            overflow-hidden 
            rounded-xl
            border
            border-gray-200
          `}
        >
          <Box className="p-5 bg-white">
            <TextField
              variant="outlined"
              placeholder="Buscar ajuda por palavra-chave..."
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: iconColor }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  bgcolor: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                  },
                  '&.Mui-focused fieldset': {
                    borderWidth: '1px',
                  },
                },
              }}
            />
          </Box>
        </Paper>

        {/* Lista de tópicos de ajuda */}
        <Box className="mb-12">
          <Typography
            variant="h5"
            className={`
              mb-6
              font-semibold 
              text-gray-800
            `}
            sx={{
              borderLeft: `4px solid ${iconColor}`,
              pl: 2.5,
              py: 1,
            }}
          >
            Perguntas Frequentes
          </Typography>

          {filteredContent.length > 0 ? (
            <Box className="space-y-4">
              {filteredContent.map((item, index) => (
                <HelpCard
                  key={`help-card-${index}`}
                  item={item}
                  isExpanded={expandedId === index}
                  onExpand={() => handleExpandCard(index)}
                  iconColor={iconColor}
                  whatsappUrl={whatsappUrl}
                />
              ))}
            </Box>
          ) : (
            <Card
              className="text-center p-8"
              elevation={0}
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.08)',
                borderRadius: '12px',
              }}
            >
              <CardContent>
                <HelpOutlineIcon
                  sx={{
                    fontSize: '4rem',
                    color: iconColor,
                    opacity: 0.7,
                    mb: 2,
                  }}
                />
                <Typography className="text-xl text-gray-700 mb-2 font-medium">
                  Nenhum resultado encontrado
                </Typography>
                <Typography className="text-gray-600">
                  Não encontramos resultados para "{searchTerm}".
                  <br />
                  Tente outros termos ou entre em contato com nosso suporte.
                </Typography>
              </CardContent>
            </Card>
          )}
        </Box>

        {/* Seção de contato direto - Apenas se não houver item de WhatsApp no conteúdo filtrado */}
        {!filteredContent.some((item) => item.Icon === WhatsAppIcon) && (
          <Card
            className="overflow-hidden"
            elevation={0}
            sx={{
              borderRadius: '16px',
              border: '1px solid rgba(0, 0, 0, 0.08)',
            }}
          >
            <Box
              className="p-6"
              sx={{
                backgroundColor: iconColor,
              }}
            >
              <Typography
                variant="h6"
                className={`
                  text-white 
                  font-medium 
                  text-center 
                  sm:text-left 
                  flex 
                  items-center 
                  justify-center 
                  sm:justify-start
                `}
              >
                <WhatsAppIcon className="mr-2" /> Precisa de mais ajuda?
              </Typography>
            </Box>
            <CardContent
              className={`
                flex 
                flex-col 
                sm:flex-row 
                items-center 
                justify-between 
                p-6
                bg-white
              `}
            >
              <Typography
                className={`
                  text-gray-700 
                  mb-4 
                  sm:mb-0 
                  text-center 
                  sm:text-left 
                  max-w-md
                `}
              >
                Nossa equipe de suporte está pronta para te ajudar com qualquer
                dúvida que você tenha.
              </Typography>
              {/* Substituído pelo componente SuporteButton */}
              <SuporteButton />
            </CardContent>
          </Card>
        )}
      </Container>
    </FullContainer>
  );
};

/**
 * Componente de card para exibição de tópicos de ajuda
 *
 * Design premium com animações suaves e feedback visual claro
 *
 * @param {Object} props - Propriedades do componente
 * @param {Object} props.item - Dados do tópico de ajuda
 * @param {boolean} props.isExpanded - Flag indicando se o card está expandido
 * @param {Function} props.onExpand - Callback para alternar a expansão
 * @param {string} props.iconColor - Cor para os ícones
 * @param {string} props.whatsappUrl - URL para contato via WhatsApp
 */
const HelpCard = ({ item, isExpanded, onExpand, iconColor, whatsappUrl }) => {
  const { Icon, title, content } = item;

  return (
    <Card
      className={`
        overflow-hidden 
        transition-all
        duration-300 
        ${isExpanded ? 'shadow-md' : 'shadow-sm'}
      `}
      elevation={0}
      sx={{
        borderRadius: '12px',
        background: 'white',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        transform: isExpanded ? 'translateY(-2px)' : 'none',
      }}
    >
      <CardActionArea
        onClick={onExpand}
        className="hover:bg-gray-50"
        aria-expanded={isExpanded}
      >
        <Box className="flex items-center p-4">
          <Box
            className={`
              w-12
              h-12
              rounded-full 
              flex 
              items-center 
              justify-center 
              flex-shrink-0
            `}
            sx={{
              background: iconColor,
              boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
              color: 'white',
            }}
          >
            <Icon className="text-2xl" />
          </Box>

          <Typography
            variant="h6"
            className={`
              font-medium 
              ml-4 
              flex-1 
              text-gray-800
            `}
            sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
          >
            {title}
          </Typography>

          <ChevronRightIcon
            className={`
              transition-transform 
              duration-300 
              ${isExpanded ? 'transform rotate-90' : ''}
            `}
            sx={{ color: iconColor }}
          />
        </Box>
      </CardActionArea>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Divider sx={{ opacity: 0.6 }} />
        <CardContent className="px-5 py-6 bg-gray-50/50">
          {content.map((text, idx) => {
            // Se for o item de WhatsApp, usamos o componente SuporteButton
            if (
              typeof text !== 'string' &&
              text.type === Box &&
              text.props?.children?.props?.startIcon?.type === WhatsAppIcon
            ) {
              return (
                <Box
                  key={nanoid()}
                  className={`
                    w-full 
                    flex 
                    flex-col 
                    sm:flex-row 
                    justify-center 
                    sm:justify-between 
                    items-center 
                    p-5 
                    rounded-lg
                    ${idx > 0 ? 'mt-5' : ''}
                  `}
                  sx={{
                    background: 'rgba(74, 85, 104, 0.05)',
                  }}
                >
                  <Typography
                    className={`
                      text-gray-700 
                      mb-4 
                      sm:mb-0 
                      sm:mr-4 
                      text-center 
                      sm:text-left
                    `}
                  >
                    Nossa equipe de suporte está disponível para te ajudar com
                    qualquer dúvida que você tenha.
                  </Typography>
                  <SuporteButton />
                </Box>
              );
            }

            return (
              <Box key={nanoid()} className={idx > 0 ? 'mt-5' : ''}>
                {typeof text === 'string' ? (
                  <Typography
                    className="text-gray-700 leading-relaxed"
                    sx={{ fontSize: '0.95rem' }}
                  >
                    {text}
                  </Typography>
                ) : (
                  text
                )}
              </Box>
            );
          })}
        </CardContent>
      </Collapse>
    </Card>
  );
};

/**
 * Dados de conteúdo dos tópicos de ajuda
 */
const content = [
  {
    title: 'Problemas ao tentar acessar',
    Icon: LoginIcon,
    content: [
      `Verifique seu email e sua senha se estão digitados corretamente e tente novamente, se mesmo assim não conseguir acessar o app, solicite "Recuperar minha Senha", insira seu email e envie.`,
      `No seu provedor de email verifique a caixa de entrada e se não ver o email para recuperar a senha, verifique também o spam.`,
      `Caso você não receba o e-mail de recuperação da senha é possível não ter digitado o email corretamente ou esse email não estar cadastrado em nosso sistema.`,
      `Se não conseguir seu acesso seguindo esses passos, entre em contato com nosso suporte, estamos aqui para ajudar você.`,
    ],
  },
  {
    title: 'Não consigo finalizar meu cadastro',
    Icon: AssignmentIcon,
    content: [
      `Para você conseguir finalizar seu cadastro é preciso preencher corretamente todas as informações.`,
      `Use um e-mail que você tenha acesso, seu cadastro mesmo que finalizado não te dará acesso antes que você verifique sua conta via o email utilizado.`,
      `Caso você já tenha feito o cadastro anteriormente e não excluiu sua conta, não será possivel criar um novo cadastro com o mesmo e-mail, você pode tentar recuperar a senha e se não conseguir entre em contato com nosso suporte, estamos aqui para ajudar você.`,
    ],
  },
  {
    title: 'Como recuperar minha senha',
    Icon: KeyIcon,
    content: [
      `Caso tenha esquecido sua senha não se preocupe, é fácil resolver.`,
      `Clique em Esqueci a Senha na tela inicial do app, digite seu email cadastrado corretamente e clique em enviar, pronto.`,
      `Abra a caixa de entrada do seu e-mail e se não encontrar nosso e-mail de recuperação de senha, veja também sua caixa de spam.`,
      `Não recebeu o e-mail, entre em contato com o suporte e ajudamos você.`,
    ],
  },
  {
    title: 'Não achou ajuda aqui? Entre em contato conosco',
    Icon: WhatsAppIcon,
    content: [
      <Box
        component="div"
        className={`
          w-full 
          flex 
          flex-col 
          sm:flex-row 
          justify-center 
          sm:justify-between 
          items-center 
          p-5 
          rounded-lg
        `}
        sx={{
          background: 'rgba(74, 85, 104, 0.05)',
        }}
      >
        <Typography
          className={`
            text-gray-700 
            mb-4 
            sm:mb-0 
            sm:mr-4 
            text-center 
            sm:text-left
          `}
        >
          Nossa equipe de suporte está disponível para te ajudar com qualquer
          dúvida que você tenha.
        </Typography>
        <SuporteButton />
      </Box>,
    ],
  },
];

export default CentralDeAjuda;
